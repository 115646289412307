import type { PageProps } from 'gatsby'
import React from 'react'
import EnterpriseFitnessLanding from 'features/enterprise/fitness/components/EnterpriseFitnessLanding'
import * as enterprise from 'libs/enterprise'
import { ReactComponent as LogosSvg } from './images/logos.svg'

type Props = PageProps

export default function EnterpriseHerring(props: Props) {
  return (
    <EnterpriseFitnessLanding
      {...props}
      logos={LogosSvg}
      partner={enterprise.HERRING_KEY}
      className="EnterpriseHerring"
    />
  )
}
